import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {TokenResponse} from 'expo-auth-session';
import dayjs from 'dayjs';
import {redirectURI, User} from '../contexts/AuthContext';
import Constants from 'expo-constants';
import {apiUrl} from '../services/request';
const { APP_ID } = Constants.manifest?.extra ?? {};

export interface AuthSlice {
    token?: string;
    refreshToken?: string;
    tokenExpiry?: number;
    error?: string;
    loading: boolean;
    user?: User;
}

const initialState: AuthSlice = {
    loading: false,
}

const postRefreshAccessToken = async (refreshToken: string) => {
    const refreshRequest = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: APP_ID,
        redirect_uri: redirectURI(),
    }
    const response = await fetch(apiUrl('oauth/token'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(refreshRequest),
    });
    return response.json();
}

export const refreshAccessToken = createAsyncThunk<any, string>(
    'auth/refreshRedditAccessToken',
    async (refreshToken) => {
        const { access_token, refresh_token, expires_in } = await postRefreshAccessToken(refreshToken);
        return {
            accessToken: access_token,
            refreshToken: refresh_token,
            expiresIn: expires_in,
            tokenType: 'Bearer',
        };
    }
)

export const logout = createAsyncThunk<void, void>(
    'auth/logout',
    async (_, { getState }) => {
        const token = (getState() as any).auth?.token;
        await fetch(apiUrl('logout'), {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` }
        })
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        cacheTokenResponse: (state, action: PayloadAction<Pick<TokenResponse, 'accessToken'|'refreshToken'|'expiresIn'|'issuedAt'>>) => {
            state.token = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            if (action.payload.issuedAt && action.payload.expiresIn)
                state.tokenExpiry = dayjs.unix(action.payload.issuedAt).add(action.payload.expiresIn, 'second').unix();
            else if (action.payload.expiresIn) state.tokenExpiry = dayjs().add(action.payload.expiresIn, 'second').unix();
        },
        setToken: (state, action: PayloadAction<string|undefined>) => {
            state.token = action.payload;
        },
        setUser: (state, action: PayloadAction<User|undefined>) => {
            state.user = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
            state.token = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.loading = false;
            if (action.payload.expiresIn) state.tokenExpiry = dayjs().add(action.payload.expiresIn, 'second').unix();
        });
        builder.addCase(refreshAccessToken.rejected, (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        });
        builder.addCase(refreshAccessToken.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(logout.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(logout.fulfilled, (state, action) => {
            state.token = undefined;
            state.refreshToken = undefined;
            state.tokenExpiry = undefined;
            state.loading = false;
            state.user = undefined;
        });
        builder.addCase(logout.rejected, (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        })
    }
})

// Action creators are generated for each case reducer function
export const { setToken, cacheTokenResponse } = authSlice.actions

export default authSlice.reducer
