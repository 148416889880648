import { Platform } from 'react-native';

export interface LoginProps {
    login: () => Promise<void>;
    loading: boolean;
}

export default function(props: LoginProps) {
    let Login;
    if (Platform.OS === 'web') {
        Login = require('./Login.web').default;
    }
    else {
        Login = require('./Login.mobile').default;
    }
    return (<Login { ...props } />);
}
