import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store';
import {useEffect} from 'react';
import {useToast} from '@chakra-ui/react';
import {removeAlert} from '../../store/alertSlice';

const typeTitle = (type: 'info' | 'success' | 'warning' | 'error') => {
    switch (type) {
        case 'info':
            return 'Info';
        case 'success':
            return 'Success';
        case 'warning':
            return 'Warning';
        case 'error':
            return 'Error';
    }
}

export default function Alert() {
    const alerts = useSelector((state: RootState) => state.alert.alerts);
    const toast = useToast();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!alerts?.length) return;
        alerts.forEach(x => {
            toast({
                title: x.title || typeTitle(x.type),
                description: x.body,
                status: x.type,
                duration: 5000,
                isClosable: true,
                onCloseComplete: () => dispatch(removeAlert(x.id)),
            })
        })
    }, [ alerts ])

    return null;
}
