import { configureStore } from '@reduxjs/toolkit'
import alertSlice from './alertSlice';
import authSlice from './authSlice';
import { authApi } from '../api/auth';

export const store = configureStore({
    reducer: {
        alert: alertSlice,
        auth: authSlice,
        [authApi.reducerPath]: authApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(authApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
