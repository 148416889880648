import React, {createContext, useContext, useState} from 'react';
import { AuthRequest, AuthRequestConfig, exchangeCodeAsync, makeRedirectUri, ResponseType } from 'expo-auth-session';
import Constants from 'expo-constants';
const { APP_ID } = Constants.manifest?.extra ?? {};
import { cacheTokenResponse } from '../store/authSlice';
import {useDispatch} from 'react-redux';
import {authDiscoveryDocument} from '../services/request';
import {useGetMeQuery} from '../api/auth';

export interface User {
    id: number;
    email: string;
}

export interface IAuthContext {
    token?: string;
    loading: boolean;
    user?: User;
    login: () => Promise<void>;
}

export const AuthContext = createContext<IAuthContext>({
    token: '',
    loading: false,
    login: () => Promise.reject('No login function provided to AuthContext!'),
})

export function useAuth() {
    const context = useContext<IAuthContext>(AuthContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return context
}

export const redirectURI = () => makeRedirectUri({ scheme: 'alac-web' });
const generateShortUUID = () => { return Math.random().toString(36).substring(2, 15) };

const buildAuthRequest = (): AuthRequest => {
    const authRequestOptions: AuthRequestConfig = {
        responseType: ResponseType.Code,
        clientId: APP_ID,
        redirectUri: redirectURI(),
        state: generateShortUUID(),
    }
    return new AuthRequest(authRequestOptions);
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [token, setToken] = useState<string>();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { data: user, isLoading, error, status } = useGetMeQuery(undefined, {
        skip: !token?.length,
    })

    const exchangeCode = async (code: string, codeVerifier?: string) => exchangeCodeAsync(
        {
            code,
            clientId: APP_ID,
            redirectUri: redirectURI(),
            extraParams: { code_verifier: codeVerifier || '' },
        },
        authDiscoveryDocument
    )

    const login = async () => {
        setLoading(true);
        const authRequest = buildAuthRequest();
        const res = await authRequest.promptAsync(authDiscoveryDocument);
        if (res.type === 'success') {
            try {
                const { accessToken, expiresIn, refreshToken, issuedAt } = await exchangeCode(res.params.code, authRequest.codeVerifier) || {};
                if (accessToken && expiresIn && refreshToken && issuedAt)
                    dispatch(cacheTokenResponse({ accessToken, issuedAt, expiresIn, refreshToken }));
                setToken(accessToken);
                setLoading(false);
            }
            catch (e) {
                setLoading(false);
                return Promise.reject(e);
            }
        }
        else {
            setLoading(false);
            return Promise.reject(res.type === 'dismiss' ? 'Login canceled' : 'Invalid credentials.');
        }
    }

    return (
        <AuthContext.Provider value={{ loading, token, login }}>
            {children}
        </AuthContext.Provider>
    )
}
