import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface Alert {
    id: string;
    type: 'success' | 'error' | 'info' | 'warning';
    title?: string;
    body: string;
}

export interface AlertSlice {
    alerts: Alert[];
}

const initialState: AlertSlice = {
    alerts: [],
}

export const counterSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        addAlert: (state, action: PayloadAction<Alert>) => {
            state.alerts.push(action.payload);
        },
        removeAlert: (state, action: PayloadAction<string>) => {
            state.alerts = state.alerts.filter(alert => alert.id !== action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { addAlert, removeAlert } = counterSlice.actions

export default counterSlice.reducer
