import FontAwesome from '@expo/vector-icons/FontAwesome';
import { DarkTheme, DefaultTheme, ThemeProvider } from '@react-navigation/native';
import { useFonts } from 'expo-font';
import {SplashScreen, Stack, useRouter, useSegments} from 'expo-router';
import {useEffect} from 'react';
import { useColorScheme } from 'react-native';
import {AuthProvider} from '../contexts/AuthContext';
import { ChakraProvider } from '@chakra-ui/react'
import {RootState, store} from '../store'
import {Provider, useSelector} from 'react-redux'
import Alert from '../components/Alert'

export {
  // Catch any errors thrown by the Layout component.
  ErrorBoundary,
} from 'expo-router';

export const unstable_settings = {
  // Ensure that reloading on `/modal` keeps a back button present.
  initialRouteName: '/(auth)/login',
};

export default function RootLayout() {
  const [loaded, error] = useFonts({
    SpaceMono: require('../assets/fonts/SpaceMono-Regular.ttf'),
    ...FontAwesome.font,
  });

  // Expo Router uses Error Boundaries to catch errors in the navigation tree.
  useEffect(() => {
    if (error) throw error;
  }, [error]);

  if (!loaded) return (<SplashScreen />);

  return (
      <Provider store={store}>
        <AuthProvider>
          <ChakraProvider>
            <Alert />
            <RootLayoutNav />
          </ChakraProvider>
        </AuthProvider>
      </Provider>
  );
}

function useProtectedRoute() {
  const segments = useSegments();
  const router = useRouter();
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    const inAuthGroup = segments[0] === '(auth)';
    // If not authed, redirect to login
    if (!token || !token.length && !inAuthGroup) router.replace('/(auth)/login');
    // If authed, redirect to index
    else if (!!token?.length && inAuthGroup) router.replace('/(tabs)');
  }, [ token, segments ])
}

function RootLayoutNav() {
  const colorScheme = useColorScheme();
  useProtectedRoute();

  return (
    <>
      <ThemeProvider value={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
        <Stack>
          <Stack.Screen name="(auth)/login" options={{ headerShown: true }} />
          <Stack.Screen name="(tabs)" options={{ headerShown: false }} />
          <Stack.Screen name="modal" options={{ presentation: 'modal' }} />
        </Stack>
      </ThemeProvider>
    </>
  );
}
