import {useAuth} from '../../contexts/AuthContext';
import * as WebBrowser from 'expo-web-browser';
import LoginComponent from '../../components/Login'
import {useDispatch} from 'react-redux';
import {addAlert} from '../../store/alertSlice';

WebBrowser.maybeCompleteAuthSession();

export default function Login() {
    const dispatch = useDispatch();
    const { login, loading } = useAuth();

    const _login = async () => {
        try {
            await login();
            dispatch(addAlert({ id: Date.now().toString(), body: 'Welcome back!', type: 'success' }))
        }
        catch (e) {
            const error = typeof e === 'string' ? e : 'Login failed';
            dispatch(addAlert({ id: Date.now().toString(), body: error, type: 'error' }))
        }
    }

    return (
        <LoginComponent
            loading={loading}
            login={_login}
        />
    )
}
