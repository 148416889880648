import Constants from 'expo-constants';
import {DiscoveryDocument} from 'expo-auth-session';
const { API_URL } = Constants.manifest?.extra ?? {};

export const baseApiUrl = (apiPrefix = 'api/v1/') => `${API_URL}${API_URL.endsWith('/') ? '' : '/'}${apiPrefix}`;

export const apiUrl = (path: string, apiPrefix = 'api/v1/') => [baseApiUrl(apiPrefix), path].join(path.startsWith('/') ? '' : '/')

export const authDiscoveryDocument: DiscoveryDocument = {
    authorizationEndpoint: apiUrl('oauth/authorize'),
    tokenEndpoint: apiUrl('oauth/token'),
    revocationEndpoint: apiUrl('oauth/revoke'),
}
