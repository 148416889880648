import React, {useMemo} from 'react';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import { Link, Tabs } from 'expo-router';
import {Pressable, useColorScheme, View} from 'react-native';
import Colors from '../../constants/Colors';
import { logout } from '../../store/authSlice';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store';
import {IconButton} from '@chakra-ui/react';
import {MaterialCommunityIcons} from '@expo/vector-icons';

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={28} style={{ marginBottom: -3 }} {...props} />;
}

export default function TabLayout() {
  const colorScheme = useColorScheme();
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: RootState) => state.auth.token);
  const isLoggedIn = useMemo(() => !!token?.length, [ token ]);

  const _logout = async () => dispatch(logout());

  return (
    <Tabs
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme ?? 'light'].tint,
      }}>
      <Tabs.Screen
        name="index"
        options={{
          title: 'Tab One',
          tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
          headerRight: () => (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {
                    isLoggedIn && (
                        <IconButton
                            style={{ marginRight: 15 }}
                            aria-label="Logout"
                            colorScheme="teal"
                            size="sm"
                            onClick={_logout}
                            icon={<MaterialCommunityIcons name="logout" size={18} color="white" />}
                        />
                    )
                }
                <Link href="/modal" asChild>
                    <Pressable>
                        {({ pressed }) => (
                            <FontAwesome
                                name="info-circle"
                                size={25}
                                color={Colors[colorScheme ?? 'light'].text}
                                style={{ marginRight: 15, opacity: pressed ? 0.5 : 1 }}
                            />
                        )}
                    </Pressable>
                </Link>
            </View>
          ),
        }}
      />
      <Tabs.Screen
        name="two"
        options={{
          title: 'Tab Two',
          tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        }}
      />
    </Tabs>
  );
}
