import { Platform } from 'react-native';

interface AlertProps {}

export default function(props: AlertProps) {
    let Alert;
    if (Platform.OS === 'web') {
        Alert = require('./Alert.web').default;
    }
    if (!Alert) return null;
    return (<Alert { ...props } />);
}
