import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react'
import {User} from '../contexts/AuthContext';
import dayjs from 'dayjs';
import {refreshAccessToken} from '../store/authSlice';
import {baseApiUrl} from '../services/request';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseApiUrl(),
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as any).auth?.token;
        if (!!token?.length) headers.set('Authorization', `Bearer ${token}`);
        return headers;
    }
})

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let query = rawBaseQuery;
    let result = await query(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        const state: any = api.getState(),
            { refreshToken, tokenExpiry } = state.auth || {};
        if (tokenExpiry && refreshToken && dayjs().isAfter(dayjs.unix(tokenExpiry))) {
            try {
                const refresh = await api.dispatch(refreshAccessToken(refreshToken)),
                    { accessToken: refreshedAccessToken } = refresh?.payload ?? {};
                result = await query(args, api, extraOptions);
            }
            catch (e) {
                console.log('refresh error', e);
            }
        }

    }
    return result;
};

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getMe: builder.query<User, void>({
            query: () => 'me',
        }),
    }),
})

export const { useGetMeQuery } = authApi
