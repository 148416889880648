import { StyleSheet } from 'react-native';
import EditScreenInfo from '../../components/EditScreenInfo';
import { Text, View } from '../../components/Themed';
import {useGetMeQuery} from '../../api/auth';
import {Button} from '@chakra-ui/react';
import {useEffect} from 'react';

export default function TabOneScreen() {
  const { data: user, refetch, isLoading, isSuccess, isError, error } = useGetMeQuery();

  const _fetchMe = () => refetch();

  return (
    <View style={styles.container}>
      {
        !!user && (
            <Text>Logged in as { user.email }</Text>
          )
      }
      <Button colorScheme="teal" onClick={_fetchMe}>
        Fetch Me
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
