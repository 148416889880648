import { LoginProps } from './index';
import { Button } from '@chakra-ui/react'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import styled from 'styled-components/native';

const Container = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
`

const Header = styled.Text`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
`

export default function Login(props: LoginProps) {
    return (
        <Container>
            <Header>Welcome to ALAC!</Header>
            <Button
                disabled={props.loading}
                isLoading={props.loading}
                colorScheme="teal"
                onClick={props.login}
                leftIcon={<MaterialCommunityIcons name="login" size={20} color="white" />}
            >Login</Button>
        </Container>
    )
}
